#Media1 {
    border-right: solid thin #080608;
    border-bottom: solid thin #080608;
    border-left: solid thin #080608;

    background-color: #8E9091;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    color: white;
    padding: 5px;
}


.ImgCarousel {
    border: thin solid #dfebeb;
    height: 260px;
    width: auto;
    max-width: 100%;
    min-width: 370px;
}

