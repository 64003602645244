#Top1 {
    border: solid thin #080608;
    background-color: #080608;
    align-items: center;
    
}

.LogoImg {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 300px;
}